/* Modern Card Styling */
.modern-card {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none;
    overflow: hidden;
  }
  
  /* Equal Height for List Cards */
  .card-equal-height {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  /* Add spacing for better readability */
  .mb-3 {
    margin-bottom: 1rem !important;
  }
  
  /* Align content for better responsiveness */
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  